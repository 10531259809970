.footer {
    margin-top: 4rem;
    border-top: 3px solid $color-black;
    background-color: #f1f1f1;

    &__container {
        margin-top: 3rem; }

    &__copyright {
        margin-top: 1rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
        border-top: 1px solid $color-black;
        font-size: 0.875rem; } }
