.features {
    display: flex;
    flex-direction: column;

    &__list {
        flex-grow: 1;
        display: flex;
        flex-wrap: wrap;

        @include sm-min {
            flex-wrap: nowrap; }

        @include lg-min {
            flex-direction: column; } }

    &__item {
        width: 50%;
        display: flex;
        padding: 0.5rem 1rem;
        flex-direction: column;
        justify-content: center;
        border-left: 2px solid $color-blue;
        background-color: $color-blue-light;

        @include sm-max {
            margin-bottom: 2px; }

        @include sm-min {
            width: 100%; }

        @include lg-min {
            flex-grow: 1; }

        &:not(:last-child) {

            @include lg-min {
                margin-bottom: 2px; } } }

    &__number {
        font-weight: 600;
        line-height: 1.2;
        font-size: 1.44rem;
        color: $color-blue;

        @include lg-min {
            font-size: 1.728rem; } }

    &__text {
        color: $color-black;

        @include lg-min {
            font-size: 1.2rem; } } }
