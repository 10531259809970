@font-face {
    font-display: swap;
    font-family: 'FPT';
    src: url('fonts/FuturaPT-Light.woff2') format('woff2'),
        url('fonts/FuturaPT-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-display: swap;
    font-family: 'FPT';
    src: url('fonts/FuturaPT-LightItalic.woff2') format('woff2'),
        url('fonts/FuturaPT-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-display: swap;
    font-family: 'FPT';
    src: url('fonts/FuturaPT-Book.woff2') format('woff2'),
        url('fonts/FuturaPT-Book.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-display: swap;
    font-family: 'FPT';
    src: url('fonts/FuturaPT-BookItalic.woff2') format('woff2'),
        url('fonts/FuturaPT-BookItalic.woff') format('woff');
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-display: swap;
    font-family: 'FPT';
    src: url('fonts/FuturaPT-Medium.woff2') format('woff2'),
        url('fonts/FuturaPT-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-display: swap;
    font-family: 'FPT';
    src: url('fonts/FuturaPT-MediumItalic.woff2') format('woff2'),
        url('fonts/FuturaPT-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-display: swap;
    font-family: 'FPT';
    src: url('fonts/FuturaPT-DemiItalic.woff2') format('woff2'),
        url('fonts/FuturaPT-DemiItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-display: swap;
    font-family: 'FPT';
    src: url('fonts/FuturaPT-Demi.woff2') format('woff2'),
        url('fonts/FuturaPT-Demi.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-display: swap;
    font-family: 'FPT';
    src: url('fonts/FuturaPT-Bold.woff2') format('woff2'),
        url('fonts/FuturaPT-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-display: swap;
    font-family: 'FPT';
    src: url('fonts/FuturaPT-BoldItalic.woff2') format('woff2'),
        url('fonts/FuturaPT-BoldItalic.woff') format('woff');
    font-weight: 700;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-display: swap;
    font-family: 'FPT';
    src: url('fonts/FuturaPT-ExtraBold.woff2') format('woff2'),
        url('fonts/FuturaPT-ExtraBold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-display: swap;
    font-family: 'FPT';
    src: url('fonts/FuturaPT-ExtraBoldItalic.woff2') format('woff2'),
        url('fonts/FuturaPT-ExtraBoldItalic.woff') format('woff');
    font-weight: 800;
    font-style: italic;
    font-display: swap;
}