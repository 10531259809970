.widgets {}

.widget {

    &:not(:last-child) {
        margin-bottom: 2rem; }

    &__title {
        margin-top: 0;
        margin-bottom: 1rem;
        padding-bottom: 0.5rem;
        border-bottom: 2px solid $color-black;
        text-transform: uppercase;
        font-size: 1rem;
        color: $color-blue; }

    &__video {
        height: 0;
        padding-bottom: 56.25%;
        position: relative;

        iframe {
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            position: absolute;
            width: 100% !important;
            height: 100% !important; } }

    &__banner {
        display: block;
        margin-left: auto;
        margin-right: auto;

        &-img {
            width: 100%;
            height: auto;
            display: block; } }

    &__img {
        width: 100%;
        height: auto;
        display: block;
        margin-left: auto;
        margin-right: auto; }

    &__nav {

        &-list {}

        &-item {

            &:not(:last-child) {
                margin-bottom: 0.25rem;
                padding-bottom: 0.25rem;
                border-bottom: 1px solid $color-gray-1; } }

        &-link {
            color: $color-black; } } }
