.menu {

    &__list {
        display: flex;
        align-items: center;
        justify-content: space-between; }

    &__item {
        position: relative;

        &:not(:first-child) {
            margin-left: 2.5rem; }

        &:hover {
            z-index: 2;

            .menu__link {
                color: $color-red;

                &::after {
                    transform: rotate(180deg); } }

            .menu__submenu-wrap {
                margin-top: 0;
                pointer-events: all;
                opacity: 1; } } }

    &__link {
        display: flex;
        position: relative;
        align-items: center;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        font-weight: 600;
        font-size: 1.125rem;
        color: $color-gray-6;
        z-index: 1;

        &:hover {
            text-decoration: none; }

        &::after {
            content: '';
            width: 1.25rem;
            height: 1.25rem;
            display: block;
            flex-shrink: 0;
            margin-top: 0.25rem;
            margin-left: 0.5rem;
            background-image: url('../img/menu-arrow.svg');
            background-repeat: no-repeat;
            background-size: 1.25rem 1.25rem;
            background-position: 0 0;
            transform: rotate(0deg);
            @include transition; }

        &.is-current {
            color: $color-red; } }


    &__item.has-subnav {}


    &__submenu {

        .simplebar-scrollbar:before {
            opacity: 0.2; }

        max-height: 400px;
        overflow-y: auto;
        padding: 0.75rem 1.5rem;

        &-wrap {
            position: absolute;
            top: 100%;
            left: -1.5rem;
            min-width: 200px;
            max-width: 250px;
            margin-top: 10px;
            background-color: $color-white;
            border-top: 2px solid $color-black;
            transition: all 0.3s ease-in-out;
            box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
            pointer-events: none;
            opacity: 0; }

        .menu__item.has-subnav:last-child & {
            left: -10px;
            right: -1.5rem; }

        &-item {

            &:not(:first-child) {
                margin-top: 0.25rem;
                padding-top: 0.25rem;
                border-top: 1px solid #f3f3f3; } }

        &-link {
            display: block;
            font-size: 1rem;
            color: $color-black;
            //white-space: nowrap
            line-height: 1rem;
            margin: 8px 0;

            &:hover {
                text-decoration: none; } } } }
