.main {

  &__container {
    padding-top: 2rem;
    border-top: 3px solid $color-black;

    &.noborder {
      border: none; }

    @include lg-min {
      display: flex;
      align-items: flex-start; } }

  &__content {
    width: 100%; }

  &__sidebar {
    width: 100%;

    @include lg-max {
      margin-top: 2rem;
      padding-top: 2rem;
      border-top: 2px solid $color-black; }

    @include lg-min {
      max-width: (3/10 * 100%);
      margin-left: 2rem; } } }
