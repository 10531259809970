html {
	overflow-x: hidden;

	&.is-offcanvas {
		overflow: hidden; } }

.container {
	width: 100%;
	max-width: 1440px;
	margin-left: auto;
	margin-right: auto;
	padding-left: 0.75rem;
	padding-right: 0.75rem;

	@include sm-min {
		padding-left: 1rem;
		padding-right: 1rem; }

	@include md-min {
		padding-left: 1.5rem;
		padding-right: 1.5rem; }

	@include hg-min {
		padding-left: 2rem;
		padding-right: 2rem; } }
