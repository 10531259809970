.table-container {
    overflow-x: auto;

    .content & {
        margin-top: 1.5rem;
        margin-bottom: 1.5rem; } }

table {
    width: 100%;
    min-width: 100%;
    background-color: transparent;
    border-collapse: collapse;

    th {
        padding: 0.75rem;
        vertical-align: bottom;
        border: 1px solid $color-table-line;
        background-color: $color-table-header;
        text-align: left;
        font-family: inherit;
        font-weight: 500;
        line-height: 1.3;
        font-size: inherit;
        color: inherit; }

    td {
        padding: 0.75rem;
        vertical-align: middle;
        border: 1px solid $color-table-line;
        font-family: inherit;
        font-weight: inherit;
        line-height: 1.3;
        font-size: inherit;
        color: inherit; } }
