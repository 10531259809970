@charset 'utf-8';

// Base
@import 'base/viriables';
@import 'base/mixins';
@import 'base/media-queries';
@import 'base/fonts';
@import 'base/main';

// Plugins
// @import 'plugins/aos'
// @import 'plugins/slick'
// @import 'plugins/magnific-popup-modified'

// Utilities
// @import 'utilities/grid'
// @import 'utilities/flex'
// @import 'utilities/layout'
// @import 'utilities/margin'
// @import 'utilities/padding'
// @import 'utilities/max-width'
// @import 'utilities/image'
// @import 'utilities/heading'
// @import 'utilities/background'
// @import 'utilities/typography'
// @import 'utilities/font'
// @import 'utilities/text'
// @import 'utilities/link'
// @import 'utilities/color'
// @import 'utilities/fill'

// Components
@import 'components/layout';
@import 'components/offcanvas';
@import 'components/header';
@import 'components/topbar';
@import 'components/mainbar';
@import 'components/logo';
@import 'components/menu';
@import 'components/breadcrumbs';
@import 'components/hero';
@import 'components/categories';
@import 'components/features';
@import 'components/main';
@import 'components/content';
@import 'components/table';
@import 'components/accordion';
@import 'components/button';
@import 'components/form';
@import 'components/links';
@import 'components/widgets';
@import 'components/footer';
@import 'components/posts';
@import 'components/top';
@import 'components/article-list';
@import 'components/modal';
@import 'components/search';
@import 'components/paginator';
@import 'components/admin-sidebar';

// Pages
// @import 'pages/home'
// @import 'pages/about'
// @import 'pages/team'
// @import 'pages/price'
// @import 'pages/brands'

.box-modal {
  background: #fff;
  padding: 10px; }

.callback-container {
  label {
    display: block; }

  textarea, input {
    border: 1px solid #aaa;
    display: block;
    width: 100%; }

  button {
    margin: 10px auto;
    border: 1px solid #aaa;
    background: none; } }

.errorlist {
  color: red;
  padding: 5px;
  border: 1px solid #eee;
  margin: 5px 0; }
