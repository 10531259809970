.categories {
    margin-bottom: -0.5rem;

    &__list {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start; }

    &__item {
        margin-bottom: 0.5rem;

        &:not(:last-child) {
            margin-right: 0.5rem; } }

    &__link {
        display: block;
        padding: 0.5rem 1.5rem;
        // border: 1px solid $color-blue
        // border-bottom-width: 2px
        background-color: $color-white;
        color: $color-blue;
        white-space: nowrap;

        &:hover,
        &.is-current {
            background-color: $color-red;
            // border-color: $color-red
            text-decoration: none;
            color: $color-white; } } }
