@font-face { font-display: swap; font-family: 'FPT'; src: url("fonts/FuturaPT-Light.woff2") format("woff2"), url("fonts/FuturaPT-Light.woff") format("woff"); font-weight: 300; font-style: normal; font-display: swap; }

@font-face { font-display: swap; font-family: 'FPT'; src: url("fonts/FuturaPT-LightItalic.woff2") format("woff2"), url("fonts/FuturaPT-LightItalic.woff") format("woff"); font-weight: 300; font-style: italic; font-display: swap; }

@font-face { font-display: swap; font-family: 'FPT'; src: url("fonts/FuturaPT-Book.woff2") format("woff2"), url("fonts/FuturaPT-Book.woff") format("woff"); font-weight: 400; font-style: normal; font-display: swap; }

@font-face { font-display: swap; font-family: 'FPT'; src: url("fonts/FuturaPT-BookItalic.woff2") format("woff2"), url("fonts/FuturaPT-BookItalic.woff") format("woff"); font-weight: 400; font-style: italic; font-display: swap; }

@font-face { font-display: swap; font-family: 'FPT'; src: url("fonts/FuturaPT-Medium.woff2") format("woff2"), url("fonts/FuturaPT-Medium.woff") format("woff"); font-weight: 500; font-style: normal; font-display: swap; }

@font-face { font-display: swap; font-family: 'FPT'; src: url("fonts/FuturaPT-MediumItalic.woff2") format("woff2"), url("fonts/FuturaPT-MediumItalic.woff") format("woff"); font-weight: 500; font-style: italic; font-display: swap; }

@font-face { font-display: swap; font-family: 'FPT'; src: url("fonts/FuturaPT-DemiItalic.woff2") format("woff2"), url("fonts/FuturaPT-DemiItalic.woff") format("woff"); font-weight: 600; font-style: italic; font-display: swap; }

@font-face { font-display: swap; font-family: 'FPT'; src: url("fonts/FuturaPT-Demi.woff2") format("woff2"), url("fonts/FuturaPT-Demi.woff") format("woff"); font-weight: 600; font-style: normal; font-display: swap; }

@font-face { font-display: swap; font-family: 'FPT'; src: url("fonts/FuturaPT-Bold.woff2") format("woff2"), url("fonts/FuturaPT-Bold.woff") format("woff"); font-weight: 700; font-style: normal; font-display: swap; }

@font-face { font-display: swap; font-family: 'FPT'; src: url("fonts/FuturaPT-BoldItalic.woff2") format("woff2"), url("fonts/FuturaPT-BoldItalic.woff") format("woff"); font-weight: 700; font-style: italic; font-display: swap; }

@font-face { font-display: swap; font-family: 'FPT'; src: url("fonts/FuturaPT-ExtraBold.woff2") format("woff2"), url("fonts/FuturaPT-ExtraBold.woff") format("woff"); font-weight: 800; font-style: normal; font-display: swap; }

@font-face { font-display: swap; font-family: 'FPT'; src: url("fonts/FuturaPT-ExtraBoldItalic.woff2") format("woff2"), url("fonts/FuturaPT-ExtraBoldItalic.woff") format("woff"); font-weight: 800; font-style: italic; font-display: swap; }

*, *::after, *::before { box-sizing: border-box; }

html { background-color: #FFFFFF; font-family: "FPT", sans-serif; line-height: 1.6; font-weight: 400; font-size: 16px; color: #000000; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; }

@media (max-width: 543px) { html { font-size: 18px; } }

@media (max-width: 991px) { html { font-size: 16px; } }

body { margin: 0; background-color: #FFFFFF; }

article, aside, footer, header, nav, section, figcaption, figure, main, details, menu { display: block; }

h1, h2, h3, h4, h5, h6 { margin-top: 1em; margin-bottom: 0.5em; font-weight: 400; }

h1, h2 { line-height: 1.1; }

h3, h4 { line-height: 1.3; }

h1 { font-size: 2.488rem; }

h2 { font-size: 2.074rem; }

h3 { font-size: 1.728rem; }

h4 { font-size: 1.44rem; }

h5 { font-weight: 500; font-size: 1.2rem; }

h6 { text-transform: uppercase; font-weight: 600; font-size: 0.833rem; }

p { margin-top: 0; margin-bottom: 0; }

a { background-color: transparent; text-decoration: none; text-decoration-skip: ink; color: #FA4132; outline-width: 0; transition: all 0.2s ease-in-out; }

a:hover { text-decoration: underline; color: #f31806; }

b, strong { font-weight: 600; }

ul, ol, dl { margin: 0; padding: 0; }

li, dt, dd { margin: 0; }

ul, ol { list-style: none; }

ul ul, ol ul { margin: 0; padding: 0; list-style: none; }

ul ol, ol ol { margin: 0; padding: 0; list-style: none; }

figure { margin: 0; }

hr { height: 0; margin: 0; overflow: visible; box-sizing: content-box; border: 0; border-top: 1px solid #000000; }

pre, code, kbd, samp { font-family: monospace, monospace; font-size: 1rem; }

abbr[title] { border-bottom: none; text-decoration: underline; text-decoration: underline dotted; }

dfn { font-style: italic; }

mark { background-color: #ff0; color: #000; }

small { font-size: 80%; }

sub, sup { position: relative; vertical-align: baseline; line-height: 0; font-size: 75%; }

sub { bottom: -0.25em; }

sup { top: -0.5em; }

audio, video { display: inline-block; }

audio, canvas, iframe, img, svg, video { vertical-align: middle; }

audio:not([controls]) { display: none; height: 0; }

img { border-style: none; }

svg:not(:root) { overflow: hidden; }

button, input, optgroup, select, textarea { margin: 0; border: 0; font-family: "FPT", sans-serif; line-height: 1.6; font-size: 1rem; -webkit-appearance: none; }

button, input { overflow: visible; }

button, select { text-transform: none; }

button, [type="reset"], [type="submit"], html [type="button"] { -webkit-appearance: button; }

button::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner { padding: 0; border-style: none; }

button:-moz-focusring, [type="reset"]:-moz-focusring, [type="button"]:-moz-focusring, [type="submit"]:-moz-focusring { outline: 1px dotted ButtonText; }

fieldset { margin: 0; padding: 0; border: 0; }

legend { padding: 0; display: table; max-width: 100%; box-sizing: border-box; color: inherit; white-space: normal; }

progress { display: inline-block; vertical-align: baseline; }

textarea { overflow: auto; resize: none; }

[type="radio"], [type="checkbox"] { padding: 0; box-sizing: border-box; }

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button { height: auto; }

[type="search"] { -webkit-appearance: textfield; outline-offset: -2px; }

[type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration { -webkit-appearance: none; }

::-webkit-file-upload-button { -webkit-appearance: button; font: inherit; }

summary { display: list-item; }

canvas { display: inline-block; }

template { display: none; }

[hidden] { display: none; }

.blocker { padding-top: 120px; }

html { overflow-x: hidden; }

html.is-offcanvas { overflow: hidden; }

.container { width: 100%; max-width: 1440px; margin-left: auto; margin-right: auto; padding-left: 0.75rem; padding-right: 0.75rem; }

@media (min-width: 544px) { .container { padding-left: 1rem; padding-right: 1rem; } }

@media (min-width: 768px) { .container { padding-left: 1.5rem; padding-right: 1.5rem; } }

@media (min-width: 1200px) { .container { padding-left: 2rem; padding-right: 2rem; } }

.offcanvas { top: 0; left: 0; width: 100%; height: 100%; display: flex; position: fixed; justify-content: flex-end; pointer-events: none; z-index: 1000; }

.offcanvas.is-open { pointer-events: all; overflow-x: hidden; overflow-y: scroll; }

.offcanvas.is-open::after { transform: translate3d(0, 0, 0); transition: opacity 0.8s; opacity: 1; }

.offcanvas::after { content: ''; top: 0; left: 0; width: 100%; height: 100%; display: block; position: fixed; background-color: rgba(0, 0, 0, 0.3); transform: translate3d(100%, 0, 0); transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1); transition: opacity 0.4s, transform 0s 0.4s; pointer-events: none; z-index: -2; opacity: 0; }

.offcanvas__close { padding: 0; width: 24px; height: 24px; display: block; margin-bottom: 1.5rem; border: 0; background-color: transparent; cursor: pointer; user-select: none; outline: 0; }

@media (min-width: 992px) { .offcanvas__close { display: none; } }

.offcanvas__close-icon { width: 100%; height: 100%; display: block; color: #000000; pointer-events: none; transition: all 0.2s ease-in-out; }

.offcanvas__close:hover .offcanvas__close-icon { color: #FA4132; }

.offcanvas__nav { width: calc(100% - 60px); max-width: 300px; min-height: 100vh; padding: 1rem 1.5rem; background-color: #FFFFFF; transform: translate3d(110%, 0, 0); transition: transform 0.2s ease-in-out; box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1); }

.is-open .offcanvas__nav { transform: translate3d(0, 0, 0); }

.offcanvas__menu-item:not(:last-child) { margin-bottom: 1rem; }

.offcanvas__menu-item.has-subnav .offcanvas__menu-link::after { content: ''; width: 1.25rem; height: 1.25rem; display: block; flex-shrink: 0; background-image: url("../img/menu-arrow.svg"); background-repeat: no-repeat; background-size: 1.25rem 1.25rem; background-position: 0 0; transform: rotate(0deg); transition: all 0.2s ease-in-out; }

.offcanvas__menu-item.has-subnav.is-expanded .offcanvas__menu-link::after { transform: rotate(180deg); }

@media (min-width: 544px) { .offcanvas__menu-item--secondary { display: none; } }

.offcanvas__menu-item--secondary:not(:last-child) { margin-bottom: 0.5rem; }

.offcanvas__menu-link { display: flex; align-items: center; justify-content: space-between; font-weight: 600; font-size: 1.125rem; color: #666666; z-index: 1; }

.offcanvas__menu-item--secondary .offcanvas__menu-link { font-size: 1rem; }

.offcanvas__menu-link:hover { text-decoration: none; }

.offcanvas__menu-link.is-current { color: #FA4132; }

.offcanvas__submenu { max-height: 0; overflow: hidden; transition: all 0.2s ease-in-out; }

.offcanvas__submenu-list { padding-top: 0.5rem; }

.offcanvas__submenu-item:not(:first-child) { margin-top: 0.25rem; padding-top: 0.25rem; border-top: 1px solid #e6e6e6; }

.offcanvas__submenu-link { display: block; font-size: 1rem; color: #000000; white-space: nowrap; }

.offcanvas__submenu-link:hover { text-decoration: none; }

.header { font-size: 14px; position: relative; background-color: #FFFFFF; z-index: 2; }

.header.is-fixed { top: 0; left: 0; right: 0; position: fixed; }

.topbar { position: relative; background-color: #052F5F; z-index: 2; }

.topbar__container { display: flex; align-items: center; padding: 10px 0; justify-content: flex-end; transition: padding 0.2s ease-in-out; }

@media (min-width: 768px) { .topbar__container { justify-content: space-between; } }

@media (min-width: 992px) { .topbar:hover .topbar__container { padding-top: 1rem; padding-bottom: 1rem; } }

.topbar__nav { display: none; margin-right: 1rem; }

@media (min-width: 768px) { .topbar__nav { display: block; } }

.topbar__nav-list { display: flex; }

.topbar__nav-item { line-height: 1.3; font-weight: 500; }

.topbar__nav-item:not(:last-child) { margin-right: 1rem; padding-right: 1.5rem; border-right: 1px solid rgba(255, 255, 255, 0.2); }

.topbar__nav-link { color: #FFFFFF; white-space: nowrap; }

.topbar__nav-link:hover { text-decoration: none; }

.topbar__options { display: flex; align-items: center; }

.topbar__languages { position: relative; margin-right: 1.5rem; padding-right: 1.5rem; border-right: 1px solid rgba(255, 255, 255, 0.2); }

.topbar__languages:hover .topbar__languages-current { color: #FA4132; }

.topbar__languages:hover .topbar__languages-current::after { transform: rotate(180deg); }

.topbar__languages:hover .topbar__languages-list { margin-top: 0; pointer-events: all; opacity: 1; }

.topbar__languages-current { display: flex; position: relative; align-items: center; font-weight: 500; color: #FFFFFF; cursor: pointer; transition: all 0.2s ease-in-out; }

.topbar__languages-current::after { content: ''; width: 1.125rem; height: 1.125rem; display: block; flex-shrink: 0; margin-top: 0.125rem; margin-left: 0.25rem; background-image: url("../img/menu-arrow.svg"); background-repeat: no-repeat; background-size: 1.125rem 1.125rem; background-position: 0 0; transform: rotate(0deg); transition: all 0.2s ease-in-out; }

.topbar__languages-list { top: 100%; left: -1rem; min-width: calc(100% + 2rem); margin-top: 10px; position: absolute; padding: 0.5rem 1rem; background-color: #FFFFFF; transition: all 0.3s ease-in-out; box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1); pointer-events: none; opacity: 0; }

.topbar__languages-item:not(:first-child) { margin-top: 0.25rem; padding-top: 0.25rem; border-top: 1px solid #f3f3f3; }

.topbar__languages-link { display: block; font-size: 1rem; color: #000000; white-space: nowrap; }

.topbar__languages-link:hover { text-decoration: none; }

.topbar__phone { display: flex; align-items: center; line-height: 1.3; font-weight: 600; color: #FFFFFF; }

.topbar__phone:hover { text-decoration: none; }

.topbar__phone-icon { width: 1.25em; height: 1.25em; display: block; margin-right: 0.5em; color: #FA4132; }

.topbar__phone-number { display: block; white-space: nowrap; }

.mainbar { padding-top: 1rem; padding-bottom: 1rem; border-bottom: 3px solid #000000; transition: padding 0.2s ease-in-out; }

@media (min-width: 992px) { .mainbar.is-small { padding-top: 0.5rem; padding-bottom: 0.5rem; } }

.mainbar__container { display: flex; align-items: center; justify-content: space-between; }

.mainbar__logo { width: 100%; max-width: 150px; transition: max-width 0.2s ease-in-out; }

@media (min-width: 544px) { .mainbar__logo { max-width: 180px; } }

@media (min-width: 768px) { .mainbar__logo { max-width: 200px; } }

@media (min-width: 992px) { .mainbar__logo { flex-shrink: 0; } }

@media (min-width: 544px) { .is-small .mainbar__logo { max-width: 150px; } }

.mainbar__menu { width: 100%; display: none; max-width: 800px; margin-left: 6rem; }

@media (min-width: 992px) { .mainbar__menu { display: block; } }

.mainbar__burger { padding: 0; width: 24px; height: 24px; display: block; margin-left: 4rem; border: 0; background-color: transparent; cursor: pointer; user-select: none; flex-shrink: 0; outline: 0; }

@media (min-width: 992px) { .mainbar__burger { display: none; } }

.mainbar__burger-icon { width: 100%; height: 100%; display: block; color: #000000; pointer-events: none; transition: all 0.2s ease-in-out; }

.mainbar__burger:hover .mainbar__burger-icon { color: #FA4132; }

.logo__link { display: block; }

.logo__img { width: 100%; height: auto; display: block; }

.menu__list { display: flex; align-items: center; justify-content: space-between; }

.menu__item { position: relative; }

.menu__item:not(:first-child) { margin-left: 2.5rem; }

.menu__item:hover { z-index: 2; }

.menu__item:hover .menu__link { color: #FA4132; }

.menu__item:hover .menu__link::after { transform: rotate(180deg); }

.menu__item:hover .menu__submenu-wrap { margin-top: 0; pointer-events: all; opacity: 1; }

.menu__link { display: flex; position: relative; align-items: center; padding-top: 0.5rem; padding-bottom: 0.5rem; font-weight: 600; font-size: 1.125rem; color: #666666; z-index: 1; }

.menu__link:hover { text-decoration: none; }

.menu__link::after { content: ''; width: 1.25rem; height: 1.25rem; display: block; flex-shrink: 0; margin-top: 0.25rem; margin-left: 0.5rem; background-image: url("../img/menu-arrow.svg"); background-repeat: no-repeat; background-size: 1.25rem 1.25rem; background-position: 0 0; transform: rotate(0deg); transition: all 0.2s ease-in-out; }

.menu__link.is-current { color: #FA4132; }

.menu__submenu { max-height: 400px; overflow-y: auto; padding: 0.75rem 1.5rem; }

.menu__submenu .simplebar-scrollbar:before { opacity: 0.2; }

.menu__submenu-wrap { position: absolute; top: 100%; left: -1.5rem; min-width: 200px; max-width: 250px; margin-top: 10px; background-color: #FFFFFF; border-top: 2px solid #000000; transition: all 0.3s ease-in-out; box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1); pointer-events: none; opacity: 0; }

.menu__item.has-subnav:last-child .menu__submenu { left: -10px; right: -1.5rem; }

.menu__submenu-item:not(:first-child) { margin-top: 0.25rem; padding-top: 0.25rem; border-top: 1px solid #f3f3f3; }

.menu__submenu-link { display: block; font-size: 1rem; color: #000000; line-height: 1rem; margin: 8px 0; }

.menu__submenu-link:hover { text-decoration: none; }

.breadcrumbs { padding-top: 0.75rem; padding-bottom: 1rem; background-color: #000000; font-size: 0.875rem; color: #FFFFFF; }

.breadcrumbs__link { text-decoration: underline; color: #FFFFFF; }

.breadcrumbs__separator { display: inline-block; width: 1rem; height: 1rem; vertical-align: middle; background-image: url("../img/breadcrumbs-arrow.svg"); background-size: 1rem 1rem; background-repeat: no-repeat; background-position: 0 0; opacity: 0.5; }

.hero { margin-top: 2rem; margin-bottom: 2rem; }

.hero__container { padding-bottom: 0.5rem; }

@media (min-width: 992px) { .hero__container { display: flex; } }

.hero__main { width: 100%; display: flex; }

@media (max-width: 991px) { .hero__sidebar { margin-top: 1.5rem; } }

@media (min-width: 992px) { .hero__sidebar { display: flex; margin-left: 2rem; flex-direction: column; width: 50%; }
  .hero--home .hero__sidebar { width: 30%; } }

.hero__banner { flex-grow: 1; display: flex; min-height: 300px; padding: 2.5em 2em; flex-direction: column; align-items: flex-start; justify-content: flex-start; position: relative; z-index: 1; }

@media (min-width: 992px) { .hero__banner { min-height: 440px; } }

.hero__banner-img { top: 0; left: 0; right: 0; bottom: 0; position: absolute; background-position: center center; background-repeat: no-repeat; background-size: cover; z-index: -1; }

.hero__banner-img::after { content: ''; top: 0; left: 0; right: 0; bottom: 0; position: absolute; background-color: rgba(0, 0, 0, 0.3); z-index: -1; }

.hero__banner-title { width: 100%; display: block; margin-top: 0; margin-bottom: 0; padding-bottom: 0.5rem; border-bottom: 1px solid #FFFFFF; line-height: 1.2; font-size: 2.074rem; color: #FFFFFF; }

@media (min-width: 992px) { .hero__banner-title { font-size: 2.5rem; } }

.hero__banner-subtitle { margin-top: 1rem; line-height: 1.3; font-size: 1.44rem; color: #FFFFFF; }

.hero__banner-button { margin-top: 4em; }

.hero__banner-categories { margin-top: auto; padding-top: 4em; }

.hero__details-container { display: flex; flex-wrap: wrap; margin-bottom: 2rem; padding: 1rem 1rem 0.75rem; background-color: #E7F1FE; font-size: 0.875rem; color: #052F5F; }

.hero__details-title { border-bottom: 1px solid #000000; margin-bottom: 10px; padding: 10px 0; }

.hero__details-label { width: 65%; margin-bottom: 0.25rem; }

.hero__details-value { width: 35%; padding-left: 1rem; margin-bottom: 0.25rem; font-weight: 500; }

.hero__details-label a, .hero__details-value a { text-decoration: underline; color: #052F5F; }

.hero__details-label a:hover, .hero__details-value a:hover { color: #FA4132; }

.hero__chapters { padding-top: 1.5rem; padding-bottom: 0.75rem; border-top: 2px solid #000000; border-bottom: 1px solid #000000; }

.hero__chapters-title { border-bottom: 1px solid #000000; margin-bottom: 10px; padding: 10px 0; }

.hero__chapters-item { padding-bottom: 0.75rem; }

.hero__chapters-link { display: flex; align-items: flex-start; color: #000000; }

.hero__chapters-link:hover::before { margin-left: 0.5em; margin-right: 0.5em; }

.hero__chapters-link::before { content: ''; display: block; width: 1em; height: 1.6em; flex-shrink: 0; margin-right: 1em; padding-top: 0.3em; padding-bottom: 0.3em; background-image: url("../img/chapter-arrow.svg"); background-repeat: no-repeat; background-size: 1em 1em; background-position: 0 center; transition: all 0.2s ease-in-out; }

.hero__features { flex-grow: 1; }

.categories { margin-bottom: -0.5rem; }

.categories__list { display: flex; flex-wrap: wrap; align-items: flex-start; }

.categories__item { margin-bottom: 0.5rem; }

.categories__item:not(:last-child) { margin-right: 0.5rem; }

.categories__link { display: block; padding: 0.5rem 1.5rem; background-color: #FFFFFF; color: #052F5F; white-space: nowrap; }

.categories__link:hover, .categories__link.is-current { background-color: #FA4132; text-decoration: none; color: #FFFFFF; }

.features { display: flex; flex-direction: column; }

.features__list { flex-grow: 1; display: flex; flex-wrap: wrap; }

@media (min-width: 544px) { .features__list { flex-wrap: nowrap; } }

@media (min-width: 992px) { .features__list { flex-direction: column; } }

.features__item { width: 50%; display: flex; padding: 0.5rem 1rem; flex-direction: column; justify-content: center; border-left: 2px solid #052F5F; background-color: #E7F1FE; }

@media (max-width: 543px) { .features__item { margin-bottom: 2px; } }

@media (min-width: 544px) { .features__item { width: 100%; } }

@media (min-width: 992px) { .features__item { flex-grow: 1; } }

@media (min-width: 992px) { .features__item:not(:last-child) { margin-bottom: 2px; } }

.features__number { font-weight: 600; line-height: 1.2; font-size: 1.44rem; color: #052F5F; }

@media (min-width: 992px) { .features__number { font-size: 1.728rem; } }

.features__text { color: #000000; }

@media (min-width: 992px) { .features__text { font-size: 1.2rem; } }

.main__container { padding-top: 2rem; border-top: 3px solid #000000; }

.main__container.noborder { border: none; }

@media (min-width: 992px) { .main__container { display: flex; align-items: flex-start; } }

.main__content { width: 100%; }

.main__sidebar { width: 100%; }

@media (max-width: 991px) { .main__sidebar { margin-top: 2rem; padding-top: 2rem; border-top: 2px solid #000000; } }

@media (min-width: 992px) { .main__sidebar { max-width: 30%; margin-left: 2rem; } }

.content > section:first-child > *:first-child { margin-top: 0; }

.content > section:last-child > *:last-child { margin-bottom: 0; }

.content h1, .content h2, .content h3, .content h4, .content h5, .content h6 { color: #052F5F; }

.content h1.underline, .content h2.underline, .content h3.underline, .content h4.underline, .content h5.underline, .content h6.underline { padding-bottom: 0.25em; border-bottom: 1px solid #052F5F; }

.content hr { margin-top: 2em; margin-bottom: 2em; }

.content p { margin-bottom: 1em; }

.content ul { margin-bottom: 1em; padding-left: 2.5rem; list-style-type: disc; }

.content ol { margin-bottom: 1em; padding-left: 2.5rem; list-style-type: decimal; }

.content li { padding-left: 0.5em; margin-bottom: 0.25em; }

.content img { width: 100%; height: auto; display: block; margin-bottom: 1rem; }

@media (max-width: 767px) { .content img { margin-left: auto; margin-right: auto; } }

@media (min-width: 768px) { .content .img-left { float: left; margin-right: 1.5rem; } }

@media (min-width: 768px) { .content .img-right { float: right; margin-left: 1.5rem; } }

@media (min-width: 768px) { .content .img-center { float: none; margin-left: auto; margin-right: auto; } }

.content figure { margin-bottom: 1rem; text-align: center; }

@media (max-width: 767px) { .content figure { margin-left: auto; margin-right: auto; } }

.content figure img { margin-bottom: 0; }

.content figure figcaption { margin-top: 0.25rem; font-size: 0.875rem; color: #4d4d4d; }

.content figure figcaption a { text-decoration: underline; color: #4d4d4d; }

.content figure figcaption a:hover { color: #FA4132; }

.content .button { margin-bottom: 1rem; }

.table-container { overflow-x: auto; }

.content .table-container { margin-top: 1.5rem; margin-bottom: 1.5rem; }

table { width: 100%; min-width: 100%; background-color: transparent; border-collapse: collapse; }

table th { padding: 0.75rem; vertical-align: bottom; border: 1px solid #cccccc; background-color: #F6F6F6; text-align: left; font-family: inherit; font-weight: 500; line-height: 1.3; font-size: inherit; color: inherit; }

table td { padding: 0.75rem; vertical-align: middle; border: 1px solid #cccccc; font-family: inherit; font-weight: inherit; line-height: 1.3; font-size: inherit; color: inherit; }

.accordion { margin-bottom: 1em; padding: 0.75rem 1rem; background-color: #E7F1FE; }

.accordion__title { display: flex; margin-top: 0; margin-bottom: 0; align-items: center; justify-content: space-between; user-select: none; cursor: pointer; }

.accordion__title::after { content: ''; width: 1.5rem; height: 1.5rem; display: block; flex-shrink: 0; background-image: url("../img/accordion-arrow.svg"); background-repeat: no-repeat; background-size: 1.5rem 1.5rem; background-position: 0 0; transform: rotate(180deg); transition: all 0.2s ease-in-out; }

.is-collapsed .accordion__title::after { transform: rotate(0deg); }

.accordion__body { overflow: hidden; transition: all 0.2s ease-in-out; }

.is-collapsed .accordion__body { max-height: 0; }

.accordion__container { padding-top: 1rem; padding-bottom: 0.5rem; }

.accordion__container > *:first-child { margin-top: 0; }

.accordion__container > *:last-child { margin-bottom: 0; }

.button { border: 0; padding: 0.5rem 1rem; display: inline-block; background-color: transparent; font-weight: 600; white-space: nowrap; cursor: pointer; user-select: none; outline: 0; transition: all 0.2s ease-in-out; }

.button[disabled] { background: #888; }

.button:hover { text-decoration: none; }

.button--lg { padding: 0.75rem 1.5rem; font-size: 1.125rem; }

.button--fill { background-color: #FA4132; color: #FFFFFF; }

.button--fill:hover { background-color: #f31806; color: #FFFFFF; }

.button--outline { border: 1px solid #FA4132; color: #FA4132; }

.button--outline:hover { border: 1px solid #f31806; color: #f31806; }

.buttons-group { display: flex; align-items: center; }

.buttons-group--center { justify-content: center; }

.buttons-group .button:not(:last-child) { margin-right: 0.5rem; }

.form { margin-top: 1.5rem; margin-bottom: 1.5rem; }

.form > *:last-child { margin-bottom: 0; }

@media (min-width: 544px) { .form__group { display: flex; align-items: center; } }

@media (min-width: 544px) { .form__group > * { width: 50%; margin-right: 1rem; } }

@media (min-width: 544px) { .form__group > *:last-child { margin-right: 0; } }

.form__field { margin-bottom: 1rem; }

.form__field--checkbox { display: flex; align-items: baseline; }

.form__field .button { margin-bottom: 0; }

.form__label { display: block; margin-bottom: 0.25rem; text-transform: uppercase; font-weight: 500; font-size: 0.813rem; color: #052F5F; }

.form__label span { color: #FA4132; }

.form__field--checkbox .form__label { margin-bottom: 0; text-transform: none; font-weight: 400; font-size: 1rem; color: #000000; }

.form__input, .form__textarea { width: 100%; display: block; padding: 0.25rem 0.5rem; border: 1px solid #A7A7A7; font-weight: inherit; line-height: inherit; font-size: inherit; color: inherit; outline: 0; transition: all 0.2s ease-in-out; }

.form__input:focus, .form__textarea:focus { border-color: #052F5F; }

.form__textarea { height: calc(((1rem * 1.6) * 5) + (0.25rem * 2)); }

.form__checkbox { margin-right: 0.5rem; appearance: checkbox; }

.form--bottom { padding: 20px; background: #f5f5f5; max-width: 500px; margin: auto; }

.form--bottom:after { box-shadow: 0 0 60px #666699; content: ""; display: block; position: absolute; z-index: -1; bottom: 10px; width: 80%; height: 40px; left: 10%; }

.form__title { font-size: 18px; text-align: center; margin-bottom: 20px; }

.links { margin-top: 2.5rem; margin-bottom: 2.5rem; padding: 2rem 1.5rem 0.5rem; border-top: 2px solid #052F5F; background-color: #E7F1FE; }

.links__row { display: flex; flex-wrap: wrap; margin-left: -1.5rem; margin-right: -1.5rem; }

.links__column { width: 100%; padding-left: 1.5rem; padding-right: 1.5rem; margin-bottom: 1.5rem; }

@media (max-width: 543px) { .links__column { padding-bottom: 1.5rem; border-bottom: 1px solid rgba(5, 47, 95, 0.3); } }

@media (min-width: 544px) { .links__column { width: 50%; border-right: 1px solid rgba(5, 47, 95, 0.3); } }

@media (min-width: 992px) { .links__column { width: 25%; } }

@media (max-width: 991px) { .links__column:nth-child(2n) { border-right: 0; } }

.links__column:last-child { border-right: 0; border-bottom: 0; padding-bottom: 0; }

.links__title { margin-top: 0; }

.links ul { padding-left: 0; margin-bottom: 0; list-style: none; }

.links li { padding-left: 0; }

.links li:last-child { margin-bottom: 0; }

.links__link { display: flex; align-items: flex-start; color: #000000; }

.links__link:hover::before { margin-left: 0.5em; margin-right: 0.5em; }

.links__link::before { content: ''; display: block; width: 1em; height: 1.6em; flex-shrink: 0; margin-right: 1em; padding-top: 0.3em; padding-bottom: 0.3em; background-image: url("../img/chapter-arrow.svg"); background-repeat: no-repeat; background-size: 1em 1em; background-position: 0 center; transition: all 0.2s ease-in-out; }

.widget:not(:last-child) { margin-bottom: 2rem; }

.widget__title { margin-top: 0; margin-bottom: 1rem; padding-bottom: 0.5rem; border-bottom: 2px solid #000000; text-transform: uppercase; font-size: 1rem; color: #052F5F; }

.widget__video { height: 0; padding-bottom: 56.25%; position: relative; }

.widget__video iframe { top: 0; left: 0; right: 0; bottom: 0; position: absolute; width: 100% !important; height: 100% !important; }

.widget__banner { display: block; margin-left: auto; margin-right: auto; }

.widget__banner-img { width: 100%; height: auto; display: block; }

.widget__img { width: 100%; height: auto; display: block; margin-left: auto; margin-right: auto; }

.widget__nav-item:not(:last-child) { margin-bottom: 0.25rem; padding-bottom: 0.25rem; border-bottom: 1px solid #e6e6e6; }

.widget__nav-link { color: #000000; }

.footer { margin-top: 4rem; border-top: 3px solid #000000; background-color: #f1f1f1; }

.footer__container { margin-top: 3rem; }

.footer__copyright { margin-top: 1rem; padding-top: 1rem; padding-bottom: 1rem; border-top: 1px solid #000000; font-size: 0.875rem; }

.posts__list { display: flex; flex-wrap: wrap; margin-left: -0.5rem; margin-right: -0.5rem; }

@media (min-width: 768px) { .posts__list { margin-left: -1rem; margin-right: -1rem; } }

.posts__item { width: 100%; display: flex; margin-bottom: 2rem; padding-left: 0.5rem; padding-right: 0.5rem; flex-direction: column; }

@media (min-width: 544px) { .posts__item { width: 50%; } }

@media (min-width: 768px) { .posts__item { width: 33.33333%; padding-left: 1rem; padding-right: 1rem; } }

@media (min-width: 992px) { .posts__item { width: 25%; } }

.posts__title { display: flex; margin-top: 0.5rem; padding-bottom: 0.5rem; border-bottom: 1px solid #000000; text-transform: uppercase; font-weight: 600; font-size: 0.833rem; color: #052F5F; }

.posts__title:hover { text-decoration: none; }

.posts__thumb { display: block; }

.posts__thumb-img { width: 100%; height: auto; display: block; }

.posts__tags { display: flex; flex-wrap: wrap; margin-top: 0.5rem; }

.posts__tags-item { line-height: 1.3; }

.posts__tags-item:not(:last-child) { margin-right: 0.5rem; }

.posts__tags-link { text-decoration: underline; font-size: 0.875rem; color: #4d4d4d; }

.top { display: flex; width: 32px; height: 32px; bottom: -110%; right: 0.75rem; position: fixed; align-items: center; justify-content: center; border-radius: 50%; background-color: #FA4132; transition: all 0.2s ease-in-out; }

@media (min-width: 544px) { .top { right: 1rem; } }

@media (min-width: 768px) { .top { right: 1.5rem; } }

@media (min-width: 1200px) { .top { right: 2rem; } }

.top.is-visible { bottom: 3rem; }

.top__icon { width: 1.5rem; height: 1.5rem; margin-bottom: 0.125rem; color: #FFFFFF; }

.article-list__item { margin-bottom: 40px; display: flex; }

.article-list__item-image { max-width: 300px; float: left; }

.article-list__item-title { font-size: 30px; }

.modal { border-radius: 0; }

.modal__title { font-size: 16px; font-weight: normal; text-transform: uppercase; text-align: center; }

.search-page .search-form { margin-bottom: 20px; }

.search-page .search-form input[type="search"] { min-width: 70%; border: 2px solid #6bacc9; }

.search-page .search-list { padding: 0; }

.search-page .search-list .item { padding: 0; margin-bottom: 20px; list-style: none; }

.search-page .search-list .item .title { text-decoration: underline; font-size: 16px; }

.search-page .search-list .item .content { font-size: 12px; }

.search-group { white-space: nowrap; position: relative; margin-top: -2px; }

.search-group__input { padding: 0 8px; height: 26px; line-height: 26px; }

.search-group__button { line-height: 26px; height: 26px; position: relative; top: 1px; }

.search-group svg { width: 10px; position: relative; top: -2px; }

.offcanvas__menu-item .search-group__input { border: 1px solid #0D3349; }

ul.pagination { padding-left: 0; margin: 0; text-align: center; }

ul.pagination li { list-style: none; display: inline-block; }

ul.pagination li a.active { color: red; font-weight: bold; }

.admin-sidebar { position: fixed; left: 0; height: 50px; width: 300px; background: white; bottom: 0; z-index: 100; padding: 20px; max-height: 100%; overflow-y: auto; }

.box-modal { background: #fff; padding: 10px; }

.callback-container label { display: block; }

.callback-container textarea, .callback-container input { border: 1px solid #aaa; display: block; width: 100%; }

.callback-container button { margin: 10px auto; border: 1px solid #aaa; background: none; }

.errorlist { color: red; padding: 5px; border: 1px solid #eee; margin: 5px 0; }
