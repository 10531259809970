.form {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;

  & > *:last-child {
    margin-bottom: 0; }

  &__group {

    @include sm-min {
      display: flex;
      align-items: center; }

    & > * {

      @include sm-min {
        width: 50%;
        margin-right: 1rem; }

      &:last-child {

        @include sm-min {
          margin-right: 0; } } } }

  &__field {
    margin-bottom: 1rem;

    &--checkbox {
      display: flex;
      align-items: baseline; }

    .button {
      margin-bottom: 0; } }

  &__label {
    display: block;
    margin-bottom: 0.25rem;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 0.813rem;
    color: $color-blue;

    span {
      color: $color-red; }

    .form__field--checkbox & {
      margin-bottom: 0;
      text-transform: none;
      font-weight: 400;
      font-size: 1rem;
      color: $color-black; } }

  &__input,
  &__textarea {
    width: 100%;
    display: block;
    padding: 0.25rem 0.5rem;
    border: 1px solid $color-line;
    font-weight: inherit;
    line-height: inherit;
    font-size: inherit;
    color: inherit;
    outline: 0;
    @include transition;

    &:focus {
      border-color: $color-blue; } }

  &__textarea {
    height: calc(((1rem * 1.6) * 5) + (0.25rem * 2)); }

  &__checkbox {
    margin-right: 0.5rem;
    appearance: checkbox; }

  &--bottom {
    padding: 20px;
    background: #f5f5f5;
    max-width: 500px;
    margin: auto;

    &:after {
      box-shadow: 0 0 60px #666699;
      content: "";
      display: block;
      position: absolute;
      z-index: -1;
      bottom: 10px;
      width: 80%;
      height: 40px;
      left: 10%; } }
  &__title {
    font-size: 18px;
    text-align: center;
    margin-bottom: 20px; } }


