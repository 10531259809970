.offcanvas {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    position: fixed;
    justify-content: flex-end;
    pointer-events: none;
    z-index: 1000;

    &.is-open {
        pointer-events: all;
        overflow-x: hidden;
        overflow-y: scroll;

        &::after {
            transform: translate3d(0, 0, 0);
            transition: opacity 0.8s;
            opacity: 1; } }

    &::after {
        content: '';
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: block;
        position: fixed;
        background-color: rgba(0, 0, 0, 0.3);
        transform: translate3d(100%, 0, 0);
        transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
        transition: opacity 0.4s, transform 0s 0.4s;
        pointer-events: none;
        z-index: -2;
        opacity: 0; }

    &__close {
        padding: 0;
        width: 24px;
        height: 24px;
        display: block;
        margin-bottom: 1.5rem;
        border: 0;
        background-color: transparent;
        cursor: pointer;
        user-select: none;
        outline: 0;

        @include lg-min {
            display: none; }

        &-icon {
            width: 100%;
            height: 100%;
            display: block;
            color: $color-black;
            pointer-events: none;
            @include transition;

            .offcanvas__close:hover & {
                color: $color-red; } } }

    &__nav {
        width: calc(100% - 60px);
        max-width: 300px;
        min-height: 100vh;
        padding: 1rem 1.5rem;
        background-color: $color-white;
        transform: translate3d(110%, 0, 0);
        transition: transform 0.2s ease-in-out;
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);

        .is-open & {
            transform: translate3d(0, 0, 0); } }

    &__menu {

        &-item {

            &:not(:last-child) {
                margin-bottom: 1rem; }

            &.has-subnav {

                .offcanvas__menu-link {

                    &::after {
                        content: '';
                        width: 1.25rem;
                        height: 1.25rem;
                        display: block;
                        flex-shrink: 0;
                        background-image: url('../img/menu-arrow.svg');
                        background-repeat: no-repeat;
                        background-size: 1.25rem 1.25rem;
                        background-position: 0 0;
                        transform: rotate(0deg);
                        @include transition; } }

                &.is-expanded {

                    .offcanvas__menu-link {

                        &::after {
                            transform: rotate(180deg); } } } }

            &--secondary {

                @include sm-min {
                    display: none; }

                &:not(:last-child) {
                    margin-bottom: 0.5rem; } } }

        &-link {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-weight: 600;
            font-size: 1.125rem;
            color: $color-gray-6;
            z-index: 1;

            .offcanvas__menu-item--secondary & {
                font-size: 1rem; }

            &:hover {
                text-decoration: none; }

            &.is-current {
                color: $color-red; } } }

    &__submenu {
        max-height: 0;
        overflow: hidden;
        @include transition;

        &-list {
            padding-top: 0.5rem; }

        &-item {

            &:not(:first-child) {
                margin-top: 0.25rem;
                padding-top: 0.25rem;
                border-top: 1px solid $color-gray-1; } }

        &-link {
            display: block;
            font-size: 1rem;
            color: $color-black;
            white-space: nowrap;

            &:hover {
                text-decoration: none; } } } }
