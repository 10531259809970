.topbar {
  position: relative;
  background-color: $color-blue;
  z-index: 2;

  &__container {
    display: flex;
    align-items: center;
    padding: 10px 0;

    justify-content: flex-end;
    transition: padding 0.2s ease-in-out;

    @include md-min {
      justify-content: space-between; }

    .topbar:hover & {

      @include lg-min {
        padding-top: 1rem;
        padding-bottom: 1rem; } } }

  &__nav {
    display: none;
    margin-right: 1rem;

    @include md-min {
      display: block; }

    &-list {
      display: flex; }

    &-item {
      line-height: 1.3;
      font-weight: 500;

      &:not(:last-child) {
        margin-right: 1rem;
        padding-right: 1.5rem;
        border-right: 1px solid rgba(255, 255, 255, 0.2); } }

    &-link {
      color: $color-white;
      white-space: nowrap;

      &:hover {
        text-decoration: none; } } }

  &__options {
    display: flex;
    align-items: center; }

  &__languages {
    position: relative;
    margin-right: 1.5rem;
    padding-right: 1.5rem;
    border-right: 1px solid rgba(255, 255, 255, 0.2);

    &:hover {

      .topbar__languages-current {
        color: $color-red;

        &::after {
          transform: rotate(180deg); } }

      .topbar__languages-list {
        margin-top: 0;
        pointer-events: all;
        opacity: 1; } }

    &-current {
      display: flex;
      position: relative;
      align-items: center;
      font-weight: 500;
      color: $color-white;
      cursor: pointer;
      @include transition;

      &::after {
        content: '';
        width: 1.125rem;
        height: 1.125rem;
        display: block;
        flex-shrink: 0;
        margin-top: 0.125rem;
        margin-left: 0.25rem;
        background-image: url('../img/menu-arrow.svg');
        background-repeat: no-repeat;
        background-size: 1.125rem 1.125rem;
        background-position: 0 0;
        transform: rotate(0deg);
        @include transition; } }

    &-list {
      top: 100%;
      left: -1rem;
      min-width: calc(100% + 2rem);
      margin-top: 10px;
      position: absolute;
      padding: 0.5rem 1rem;
      background-color: $color-white;
      transition: all 0.3s ease-in-out;
      box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
      pointer-events: none;
      opacity: 0; }

    &-item {

      &:not(:first-child) {
        margin-top: 0.25rem;
        padding-top: 0.25rem;
        border-top: 1px solid #f3f3f3; } }

    &-link {
      display: block;
      font-size: 1rem;
      color: $color-black;
      white-space: nowrap;

      &:hover {
        text-decoration: none; } } }

  &__phone {
    display: flex;
    align-items: center;
    line-height: 1.3;
    font-weight: 600;
    color: $color-white;

    &:hover {
      text-decoration: none; }

    &-icon {
      width: 1.25em;
      height: 1.25em;
      display: block;
      margin-right: 0.5em;
      color: $color-red; }

    &-number {
      display: block;
      white-space: nowrap; } } }
