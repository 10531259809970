.breadcrumbs {
    padding-top: 0.75rem;
    padding-bottom: 1rem;
    background-color: $color-black;
    font-size: 0.875rem;
    color: $color-white;

    &__link {
        text-decoration: underline;
        color: $color-white; }

    &__separator {
        display: inline-block;
        width: 1rem;
        height: 1rem;
        vertical-align: middle;
        background-image: url('../img/breadcrumbs-arrow.svg');
        background-size: 1rem 1rem;
        background-repeat: no-repeat;
        background-position: 0 0;
        opacity: 0.5; } }
