*,
*::after,
*::before {
  box-sizing: border-box; }

html {
  background-color: $color-white;
  font-family: $font-family;
  line-height: 1.6;
  font-weight: 400;
  font-size: 16px;
  color: $color-black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;

  @include sm-max {
    $html-font-size: (544px: 14px, 992px: 16px);
    font-size: 18px; }

  @include lg-max {
    font-size: 16px; } }

body {
  margin: 0;
  background-color: $color-white; }

article,
aside,
footer,
header,
nav,
section,
figcaption,
figure,
main,
details,
menu {
  display: block; }

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 1em;
  margin-bottom: 0.5em;
  font-weight: 400; }

h1,
h2 {
  line-height: 1.1; }

h3,
h4 {
  line-height: 1.3; }

h1 {
  font-size: 2.488rem; }

h2 {
  font-size: 2.074rem; }

h3 {
  font-size: 1.728rem; }

h4 {
  font-size: 1.44rem; }

h5 {
  font-weight: 500;
  font-size: 1.2rem; }

h6 {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 0.833rem; }

p {
  margin-top: 0;
  margin-bottom: 0; }

a {
  background-color: transparent;
  text-decoration: none;
  text-decoration-skip: ink;
  color: $color-link;
  outline-width: 0;
  @include transition;

  &:hover {
    text-decoration: underline;
    color: $color-link-hover; } }

b,
strong {
  font-weight: 600; }

ul,
ol,
dl {
  margin: 0;
  padding: 0; }

li,
dt,
dd {
  margin: 0; }

ul,
ol {
  list-style: none;

  ul {
    margin: 0;
    padding: 0;
    list-style: none; }

  ol {
    margin: 0;
    padding: 0;
    list-style: none; } }

figure {
  margin: 0; }

hr {
  height: 0;
  margin: 0;
  overflow: visible;
  box-sizing: content-box;
  border: 0;
  border-top: 1px solid $color-black; }

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1rem; }

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted; }

dfn {
  font-style: italic; }

mark {
  background-color: #ff0;
  color: #000; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  vertical-align: baseline;
  line-height: 0;
  font-size: 75%; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

audio,
video {
  display: inline-block; }

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle; }

audio:not([controls]) {
  display: none;
  height: 0; }

img {
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  border: 0;
  font-family: $font-family;
  line-height: 1.6;
  font-size: 1rem;
  -webkit-appearance: none; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
[type="reset"],
[type="submit"],
html [type="button"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

button:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="button"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText; }

fieldset {
  margin: 0;
  padding: 0;
  border: 0; }

legend {
  padding: 0;
  display: table;
  max-width: 100%;
  box-sizing: border-box;
  color: inherit;
  white-space: normal; }

progress {
  display: inline-block;
  vertical-align: baseline; }

textarea {
  overflow: auto;
  resize: none; }

[type="radio"],
[type="checkbox"] {
  padding: 0;
  box-sizing: border-box; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit; }

summary {
  display: list-item; }

canvas {
  display: inline-block; }

template {
  display: none; }

[hidden] {
  display: none; }

.blocker {
  padding-top: 120px; }
