ul.pagination {
    padding-left: 0;
    margin: 0;
    text-align: center;

    li {
        list-style: none;
        display: inline-block;


        a.active {
            color: red;
            font-weight: bold;
        }

    }
}