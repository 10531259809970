// TYPOGRAPHY
$font-family: 'FPT', sans-serif;

// COLORS
$color-black: #000000;
$color-white: #FFFFFF;
$color-blue: #052F5F;
$color-blue-light: #E7F1FE;
$color-red: #FA4132;

// COLOR / Blacks
// $color-black-1: darken($color-white, 90%)
// $color-black-2: darken($color-white, 80%)
// $color-black-3: darken($color-white, 70%)
// $color-black-4: darken($color-white, 60%)
// $color-black-5: darken($color-white, 50%)
// $color-black-6: darken($color-white, 40%)
// $color-black-7: darken($color-white, 30%)
// $color-black-8: darken($color-white, 20%)
// $color-black-9: darken($color-white, 10%)

// COLOR / Grays
$color-gray-1: lighten($color-black, 90%);
$color-gray-2: lighten($color-black, 80%);
$color-gray-3: lighten($color-black, 70%);
$color-gray-4: lighten($color-black, 60%);
$color-gray-5: lighten($color-black, 50%);
$color-gray-6: lighten($color-black, 40%);
$color-gray-7: lighten($color-black, 30%);
$color-gray-8: lighten($color-black, 20%);
$color-gray-9: lighten($color-black, 10%);

// COLORS / Line
$color-line: #A7A7A7;

// COLORS / Table
$color-table-line: $color-gray-2;
$color-table-header: #F6F6F6;

// COLORS / Link
$color-link: $color-red;
$color-link-hover: darken($color-link, 10%);
