.content {

    & > *:first-child {}
    & > section:first-child > *:first-child {
        margin-top: 0; }

    & > *:last-child {}
    & > section:last-child > *:last-child {
        margin-bottom: 0; }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: $color-blue;

        &.underline {
            padding-bottom: 0.25em;
            border-bottom: 1px solid $color-blue; } }

    hr {
        margin-top: 2em;
        margin-bottom: 2em; }

    p {
        margin-bottom: 1em; }

    ul {
        margin-bottom: 1em;
        padding-left: 2.5rem;
        list-style-type: disc; }

    ol {
        margin-bottom: 1em;
        padding-left: 2.5rem;
        list-style-type: decimal; }

    li {
        padding-left: 0.5em;
        margin-bottom: 0.25em; }

    img {
        width: 100%;
        height: auto;
        display: block;
        margin-bottom: 1rem;

        @include md-max {
            margin-left: auto;
            margin-right: auto; } }

    .img-left {

        @include md-min {
            float: left;
            margin-right: 1.5rem; } }

    .img-right {

        @include md-min {
            float: right;
            margin-left: 1.5rem; } }

    .img-center {

        @include md-min {
            float: none;
            margin-left: auto;
            margin-right: auto; } }

    figure {
        margin-bottom: 1rem;
        text-align: center;

        @include md-max {
            margin-left: auto;
            margin-right: auto; }

        img {
            margin-bottom: 0; }

        figcaption {
            margin-top: 0.25rem;
            font-size: 0.875rem;
            color: $color-gray-7;

            a {
                text-decoration: underline;
                color: $color-gray-7;

                &:hover {
                    color: $color-red; } } } }

    .button {
        margin-bottom: 1rem; } }
