.article-list {
    &__item {
        margin-bottom: 40px;
        display: flex;

        &-image {
            max-width: 300px;
            float: left; }

        &-title {
            font-size: 30px; }

        &-text {} } }
