.top {
    display: flex;
    width: 32px;
    height: 32px;
    bottom: -110%;
    right: 0.75rem;
    position: fixed;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: $color-red;
    @include transition;

    @include sm-min {
        right: 1rem; }

    @include md-min {
        right: 1.5rem; }

    @include hg-min {
        right: 2rem; }

    &.is-visible {
        bottom: 3rem; }

    &__icon {
        width: 1.5rem;
        height: 1.5rem;
        margin-bottom: 0.125rem;
        color: $color-white; } }
