.accordion {
    margin-bottom: 1em;
    padding: 0.75rem 1rem;
    background-color: $color-blue-light;

    &__title {
        display: flex;
        margin-top: 0;
        margin-bottom: 0;
        align-items: center;
        justify-content: space-between;
        user-select: none;
        cursor: pointer;

        &::after {
            content: '';
            width: 1.5rem;
            height: 1.5rem;
            display: block;
            flex-shrink: 0;
            background-image: url('../img/accordion-arrow.svg');
            background-repeat: no-repeat;
            background-size: 1.5rem 1.5rem;
            background-position: 0 0;
            transform: rotate(180deg);
            @include transition;

            .is-collapsed & {
                transform: rotate(0deg); } } }

    &__body {
        overflow: hidden;
        @include transition;

        .is-collapsed & {
            max-height: 0; } }

    &__container {
        padding-top: 1rem;
        padding-bottom: 0.5rem;

        & > *:first-child {
            margin-top: 0; }

        & > *:last-child {
            margin-bottom: 0; } } }
