@mixin transition {
	transition: all 0.2s ease-in-out
}

@function linear-interpolation($map) {
	$keys: map-keys($map);
	@if (length($keys) != 2) {
		@error "linear-interpolation() $map must be exactly 2 values";
	}
	$m: (map-get($map, nth($keys, 2)) - map-get($map, nth($keys, 1)))/(nth($keys, 2) - nth($keys,1));
	$b: map-get($map, nth($keys, 1)) - $m * nth($keys, 1);
	$sign: "+";
	@if ($b < 0) {
		$sign: "-";
		$b: abs($b);
	}
	@return calc(#{$m*100}vw #{$sign} #{$b});
}

@mixin test {
	background-color: rgba(250, 43, 252, 0.6)
}