.header {
    font-size: 14px;
    position: relative;
    background-color: $color-white;
    z-index: 2;

    &.is-fixed {
        top: 0;
        left: 0;
        right: 0;
        position: fixed; } }
