.button {
    border: 0;
    padding: 0.5rem 1rem;
    display: inline-block;
    background-color: transparent;
    font-weight: 600;
    white-space: nowrap;
    cursor: pointer;
    user-select: none;
    outline: 0;
    @include transition;

    &[disabled] {
        background: #888; }

    &:hover {
        text-decoration: none; }

    &--small {}

    &--lg {
        padding: 0.75rem 1.5rem;
        font-size: 1.125rem; }

    &--fill {
        background-color: $color-red;
        color: $color-white;

        &:hover {
            background-color: darken($color-red, 10%);
            color: $color-white; } }

    &--outline {
        border: 1px solid $color-red;
        color: $color-red;

        &:hover {
            border: 1px solid darken($color-red, 10%);
            color: darken($color-red, 10%); } } }

.buttons-group {
    display: flex;
    align-items: center;

    &--center {
        justify-content: center; }

    .button {

        &:not(:last-child) {
            margin-right: 0.5rem; } } }
