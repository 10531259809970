.links {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
    padding: 2rem 1.5rem 0.5rem;
    border-top: 2px solid $color-blue;
    background-color: $color-blue-light;

    &__row {
        display: flex;
        flex-wrap: wrap;
        margin-left: -1.5rem;
        margin-right: -1.5rem; }

    &__column {
        width: 100%;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        margin-bottom: 1.5rem;

        @include sm-max {
            padding-bottom: 1.5rem;
            border-bottom: 1px solid rgba(5, 47, 95, 0.3); }

        @include sm-min {
            width: 50%;
            border-right: 1px solid rgba(5, 47, 95, 0.3); }

        @include lg-min {
            width: 25%; }

        &:nth-child(2n) {

            @include lg-max {
                border-right: 0; } }

        &:last-child {
            border-right: 0;
            border-bottom:: 0 {}
            padding-bottom: 0; } }

    &__title {
        margin-top: 0; }

    ul {
        padding-left: 0;
        margin-bottom: 0;
        list-style: none; }

    li {
        padding-left: 0;

        &:last-child {
            margin-bottom: 0; } }

    &__link {
        display: flex;
        align-items: flex-start;
        color: $color-black;

        &:hover {

            &::before {
                margin-left: 0.5em;
                margin-right: 0.5em; } }

        &::before {
            content: '';
            display: block;
            width: 1em;
            height: 1.6em;
            flex-shrink: 0;
            margin-right: 1em;
            padding-top: 0.3em;
            padding-bottom: 0.3em;
            background-image: url('../img/chapter-arrow.svg');
            background-repeat: no-repeat;
            background-size: 1em 1em;
            background-position: 0 center;
            @include transition; } } }
