.posts {

    &__list {
        display: flex;
        flex-wrap: wrap;
        margin-left: -0.5rem;
        margin-right: -0.5rem;

        @include md-min {
            margin-left: -1rem;
            margin-right: -1rem; } }

    &__item {
        width: 100%;
        display: flex;
        margin-bottom: 2rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        flex-direction: column;

        @include sm-min {
            width: (6/12 * 100%); }

        @include md-min {
            width: (4/12 * 100%);
            padding-left: 1rem;
            padding-right: 1rem; }

        @include lg-min {
            width: (3/12 * 100%); } }

    &__title {
        //flex-grow: 1
        display: flex;
        margin-top: 0.5rem;
        padding-bottom: 0.5rem;
        border-bottom: 1px solid $color-black;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 0.833rem;
        color: $color-blue;

        &:hover {
            text-decoration: none; } }

    &__thumb {
        display: block;

        &-img {
            width: 100%;
            height: auto;
            display: block; } }

    &__tags {
        display: flex;
        flex-wrap: wrap;
        margin-top: 0.5rem;

        &-item {
            line-height: 1.3;

            &:not(:last-child) {
                margin-right: 0.5rem; } }

        &-link {
            text-decoration: underline;
            font-size: 0.875rem;
            color: $color-gray-7; } } }
