.admin-sidebar {
    position: fixed;
    left: 0;
    height: 50px;
    width: 300px;
    background: white;
    bottom: 0;
    z-index: 100;
    padding: 20px;
    max-height: 100%;
    overflow-y: auto;
}
