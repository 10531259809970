.hero {
  margin-top: 2rem;
  margin-bottom: 2rem;

  &__container {
    padding-bottom: 0.5rem;

    @include lg-min {
      display: flex; } }

  &__main {
    width: 100%;
    display: flex; }

  &__sidebar {

    @include lg-max {
      margin-top: 1.5rem; }

    @include lg-min {
      display: flex;
      margin-left: 2rem;
      flex-direction: column;
      width: (6/12 * 100%);

      .hero--home & {
        width: (3/10 * 100%); } } }

  &__banner {
    flex-grow: 1;
    display: flex;
    min-height: 300px;
    padding: 2.5em 2em;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    z-index: 1;

    @include lg-min {
      min-height: 440px; }

    &-img {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      position: absolute;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      z-index: -1;

      &::after {
        content: '';
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: absolute;
        background-color: rgba(0, 0, 0, 0.3);
        z-index: -1; } }

    &-title {
      width: 100%;
      display: block;
      margin-top: 0;
      margin-bottom: 0;
      padding-bottom: 0.5rem;
      border-bottom: 1px solid $color-white;
      line-height: 1.2;
      font-size: 2.074rem;
      color: $color-white;

      @include lg-min {
        font-size: 2.5rem; } }

    &-subtitle {
      margin-top: 1rem;
      line-height: 1.3;
      font-size: 1.44rem;
      color: $color-white; }

    &-button {
      margin-top: 4em; }

    &-categories {
      margin-top: auto;
      padding-top: 4em; } }

  &__details {

    &-container {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 2rem;
      padding: 1rem 1rem 0.75rem;
      background-color: $color-blue-light;
      font-size: 0.875rem;
      color: $color-blue; }

    &-title {
      border-bottom: 1px solid $color-black;
      margin-bottom: 10px;
      padding: 10px 0; }

    &-label {
      width: 65%;
      margin-bottom: 0.25rem; }

    &-value {
      width: 35%;
      padding-left: 1rem;
      margin-bottom: 0.25rem;
      font-weight: 500; }

    &-label,
    &-value {

      a {
        text-decoration: underline;
        color: $color-blue;

        &:hover {
          color: $color-red; } } } }

  &__chapters {
    padding-top: 1.5rem;
    padding-bottom: 0.75rem;
    border-top: 2px solid $color-black;
    border-bottom: 1px solid $color-black;

    &-title {
      border-bottom: 1px solid $color-black;
      margin-bottom: 10px;
      padding: 10px 0; }

    &-item {
      padding-bottom: 0.75rem; }

    &-link {
      display: flex;
      align-items: flex-start;
      color: $color-black;

      &:hover {

        &::before {
          margin-left: 0.5em;
          margin-right: 0.5em; } }

      &::before {
        content: '';
        display: block;
        width: 1em;
        height: 1.6em;
        flex-shrink: 0;
        margin-right: 1em;
        padding-top: 0.3em;
        padding-bottom: 0.3em;
        background-image: url('../img/chapter-arrow.svg');
        background-repeat: no-repeat;
        background-size: 1em 1em;
        background-position: 0 center;
        @include transition; } } }

  // &__categories
  //     margin-top: 1.5rem

  //     &-list
  //         display: flex
  //         flex-wrap: wrap
  //         align-items: flex-start

  //     &-item
  //         margin-bottom: 0.5rem

  //         &:not(:last-child)
  //             margin-right: 0.5rem

  //     &-link
  //         display: block
  //         padding: 0.5rem 1.5rem
  //         border: 1px solid $color-blue
  //         border-bottom-width: 2px
  //         color: $color-blue
  //         white-space: nowrap

  //         &:hover
  //             border-color: $color-red
  //             text-decoration: none

  //         &.is-current
  //             border-color: $color-red
  //             color: $color-red

  &__features {
    flex-grow: 1; } }
