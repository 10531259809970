.search-page {
    .search-form {
        input[type="search"] {
            min-width: 70%;
            border: 2px solid #6bacc9;
        }

        margin-bottom: 20px;
    }


    .search-list {

        padding: 0;

        .item {
            padding: 0;
            margin-bottom: 20px;
            list-style: none;

            .title {
                text-decoration: underline;
                //font-weight: bold;
                font-size: 16px;
            }

            .content {
                font-size: 12px;
            }
        }
    }
}

.search-group {
    white-space: nowrap;
    position: relative;
    margin-top: -2px;

    &__input {
        padding: 0 8px;
        height: 26px;
        line-height: 26px;
    }

    &__button {
        line-height: 26px;
        height: 26px;
        position: relative;
        top: 1px;
    }

    svg {
        width: 10px;
        position: relative;
        top: -2px;
    }
}

.offcanvas__menu-item .search-group__input {
    border: 1px solid #0D3349;
}