.mainbar {
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-bottom: 3px solid $color-black;
    transition: padding 0.2s ease-in-out;

    &.is-small {

        @include lg-min {
            padding-top: 0.5rem;
            padding-bottom: 0.5rem; } }

    // +lg-min
    //     padding-top: 1rem
    //     padding-bottom: 1rem

    &__container {
        display: flex;
        align-items: center;
        justify-content: space-between; }

    &__logo {
        width: 100%;
        max-width: 150px;
        transition: max-width 0.2s ease-in-out;

        @include sm-min {
            max-width: 180px; }

        @include md-min {
            max-width: 200px; }

        @include lg-min {
            flex-shrink: 0; }

        .is-small & {

            @include sm-min {
                max-width: 150px; } } }

    &__menu {
        width: 100%;
        display: none;
        max-width: 800px;
        margin-left: 6rem;

        @include lg-min {
            display: block; } }

    &__burger {
        padding: 0;
        width: 24px;
        height: 24px;
        display: block;
        margin-left: 4rem;
        border: 0;
        background-color: transparent;
        cursor: pointer;
        user-select: none;
        flex-shrink: 0;
        outline: 0;

        @include lg-min {
            display: none; }

        &-icon {
            width: 100%;
            height: 100%;
            display: block;
            color: $color-black;
            pointer-events: none;
            @include transition;

            .mainbar__burger:hover & {
                color: $color-red; } } } }
