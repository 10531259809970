@mixin xs-max {
	@media (max-width: 374px) { @content }
}

@mixin xs-min {
	@media (min-width: 375px) { @content }
}

@mixin sm-max {
	@media (max-width: 543px) { @content }
}

@mixin sm-min {
	@media (min-width: 544px) { @content }
}

@mixin md-max {
	@media (max-width: 767px) { @content }
}

@mixin md-min {
	@media (min-width: 768px) { @content }
}

@mixin lg-max {
	@media (max-width: 991px) { @content }
}

@mixin lg-min {
	@media (min-width: 992px) { @content }
}

@mixin hg-max {
	@media (max-width: 1199px) { @content }
}

@mixin hg-min {
	@media (min-width: 1200px) { @content }
}